import Articles from "../../JSON/articles.json"
import {useEffect, useState} from "react"

const Blog = () => {
    const [feed, setFeed] = useState()
    
    
    return(
        <div class = "flex flex-col w-screen my-[50px]">
            <h2 class = "text-3xl my-5 text-white font-extrabold text-center">Projects & Blog</h2>
            <p class = "text-base mb-5 font-light text-white text-center">I've written a few articles that you can check out that go more into depth on my projects.</p>
            <div class = "mx-[150px]">
              {
                Articles.Articles.map((items, key) => (
                    <div class = "my-10">
                    <div class = "flex flex-row ">
                      <div class = "flex flex-col justify-center"> 
                        <a href = {items.link}>
                                <div key = {key} class = "mx-auto cursor-pointer grid grid-cols-1 place-items-start w-10/12 mx-5 mb-12">
                                    <h2 class = "text-white text-start font-extrabold text-2xl my-2.5">{items.title}</h2>
                                    <p class = "text-gray-300 text-start font-bold font-sm mb-2.5">{items.pubDate}</p>
                                    <p class = "text-gray-300 text-start font-bold font-sm mb-3">{items.desc}</p>

                                </div>
                            </a>
                        </div>
                        
                    </div>
                    <div class="w-full mb-10 h-[1px] background-white" style={{backgroundColor: 'white'}}></div>
                    </div>
                    
                ))
              }
          </div>

        </div>
    )
}
export default Blog
