import { BrowserRouter, Routes, Route} from "react-router-dom";
import "./App.css";
import Blog from "./Pages/Blog";
import Home from "./Pages/Home";

function App() {
  return (
        <BrowserRouter>
            <Routes>
                <Route path ="/" element={<Home/>}/>
                <Route path = "/blog/:article" element={<Blog/>} /> 
            </Routes>
        </BrowserRouter>
  );
}

export default App;
