import Resume from "../../JSON/Resume.json";
import "animate.css";
import React, {useState} from "react"
const EducationResume = () => {
  const [select, selection] = useState()
  const [arr, setArr] = useState([])



  return (
    <div class = "">
      <h2 class="text-3xl animate__backInDown font-extrabold text-center py-10 text-white">
        Education
      </h2>
      <p class="text-base animate__backInDown text-center text-gray-200 mb-12 ">
        Learn More About My Work Experience
      </p>
      <div class={select != null ? "grid grid-cols-1 lg:grid-cols-2 m-20 place-items-center h-screen  lg:h-[500px] [ease-out duration-1000" : " transition ease-out duration-1000 grid grid-cols-1 lg:grid-cols-1 m-20 place-items-center h-screen lg:h-[500px]"}>
        
        <div>
          {Resume.Education.map((item, key) => (
            <>
            {item.name === select? 
            <>
              <div class = " text-white m-5 text-2xl font-semibold cursor-pointer">
                {item.name}
              </div>
            </>
            : 
            <>
              <div onClick = {() => {
                selection(item.name)
                setArr(item)
              }}
              class = "hover:text-slate-300 transition ease-in duration-300 text-slate-500 m-5 text-2xl cursor-pointer ">
                {item.name}
              </div>
            
            </>
            
            }
            </>
          ))}
        </div>
        <div class = {select != null ? "block" : "hidden"}>
          <div class = "text-white text-3xl  my-2.5 lg:m-5 text-center lg:text-left">
          {arr.role}
          </div>
          <div class = "text-white text-xl  my-2.5 lg:m-5 text-center lg:text-left">
          {arr.date}
          </div>
         
          <div class = "text-white text-base  my-2.5 lg:m-5 text-center lg:text-left">
            {arr.desc}
          </div>

        </div>
      </div>
    </div>
  );
};
export default EducationResume;
