import emailjs from "@emailjs/browser"
import {useRef} from "react"
const ContactForm = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_jq7ecra', 'template_1a0tk09', form.current, "ggCyyzMyBDuvOfzlV")
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

    }
    
    return(
        <>
        <div class = "lg:py-16 mx-auto mt-10 py-10 w-9/12 max-w-screen-md">
            <h2 class = "mb-4 text-3xl font-extrabold text-white text-center">
                Contact Me
            </h2>
            <p class = "mb-12 text-base text-gray-200 font-light text-center">
                Do you want to get a hold of me. Please fill out the contact form. I'm more than willing to meet new people
            </p>
            <form ref = {form} action ="#" class = "space-y-8">
                <div>
                    <label for = "email" class = "block mb-2 text-start font-light text-gray-200">
                        Email
                    </label>
                    <input placeholder = "Email" type = "email" id = "email" class = "shadow-sm text-white border bg-gray-900 border-transparent rounded focus:ring-primary-500 text-sm focus:border-primary-500 w-full p-2.5"/>
                </div>
                <div>
                    <label for = "email" class = "block mb-2 text-start font-light text-gray-200">
                        Name
                    </label>
                    <input placeholder = "Name" type = "email" id = "email" class = "shadow-sm  text-white border-transparent bg-gray-900 border-transparent rounded focus:ring-primary-500 text-sm focus:border-primary-500 w-full p-2.5"/>
                </div>
                <div>
                    <label for = "email" class = "block mb-2 text-start font-light text-gray-200">
                        Messages
                    </label>
                    <textarea placeholder = "Message" rows="6" class = "block p-2.5 w-full text-white border border-transparent bg-gray-900 rounded"/>
                </div>
                <button class = "bg-gray-900 w-24 h-10 text-white py-2 px-5 text-center rounded-lg duration-300 ease-in hover:bg-sky-900">Submit</button>
            </form>
        </div>

        </>
    )
}
export default ContactForm;