import ReactMarkdown from 'react-markdown';
import React from 'react';
import { useParams } from 'react-router-dom';
import BlogNavbar from '../Components/Navbar/BlogNavbar';
import CodeBlock from '../Components/CodeBlocks/CodeBlocks';
import rehypeHighlight from 'rehype-highlight'; // Syntax highlighting
import rehypeRaw from 'rehype-raw';
import 'highlight.js/styles/github.css'; // Import a highlight.js theme
const Blog = () => {
    const { article } = useParams();
    const [markdownContent, setMarkdownContent] = React.useState('');
    console.log(article);
    React.useEffect(() => {
        import(`../Articles/${article}.md`)
            .then((res) => {
                fetch(res.default)
                .then(res => res.text())
                .then(res => setMarkdownContent(res))
                .catch((error) => console.log(error));
            })
            .catch((err) => console.log(err))
    }, []);
    return(
        <div className="text-white h-full w-screen bg-slate-800">
            <BlogNavbar/>
            <div className="markdown w-10/12  mx-auto">
                <ReactMarkdown rehypePlugins={[rehypeRaw,rehypeHighlight]}>{markdownContent}</ReactMarkdown>
            </div>
            <div className="h-[50px]"/>
        </div>
    );
}
export default Blog;
