import * as FA from "react-icons/fa";
import * as BS from "react-icons/bs";
import * as AI from "react-icons/ai";
import * as GR from "react-icons/gr";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Navbar.css";
import { useNavigation } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <div class="absolute p-4 shadow-lg mx-auto sticky top-5 w-[300px] h-[60px] border-transparent rounded-full bg-gray-900 opacity-80">
        <div class="grid grid-cols-4 place-items-center">
          <div class="cursor-pointer duration-300 ease-in hover:bg-white hover:text-black rounded-full h-8 w-8 grid place-items-center items-center p-0.5 text-slate-300">
            <span class="text-2xl">
              <Link
                activeClass=""
                to="section0"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <AI.AiOutlineHome />
              </Link>
            </span>
          </div>
          <div class="cursor-pointer duration-300 ease-in hover:bg-white hover:text-black rounded-full h-8 w-8 grid place-items-center items-center p-0.5 text-slate-300">
            <span class="text-2xl">
              <Link
                activeClass=""
                to="section1"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <AI.AiOutlineProject />
              </Link>
            </span>
          </div>
          <div class="cursor-pointer duration-300 ease-in hover:bg-white hover:text-black rounded-full h-8 w-8 grid place-items-center items-center p-0.5 text-slate-300">
            <span class="text-2xl">
              <Link
                activeClass=""
                to="section2"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <BS.BsCardList />
              </Link>
            </span>
          </div>
          <div class="cursor-pointer duration-300 ease-in hover:bg-white hover:text-black rounded-full h-8 w-8 grid place-items-center items-center p-0.5 text-slate-300">
            <span class="text-2xl">
              <Link
                activeClass=""
                to="section3"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <AI.AiOutlineContacts />
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
