import Skills from "../../JSON/Skills.json"
const SkillCard = () => {
    return (
        <>
             <h2 class = "text-3xl my-5 text-white font-extrabold text-center">Skills</h2>
            <p class = "text-base mb-5 font-light text-gray-200 text-ceter">Learn more about my skills and how they could be useful in industry.</p>
            <div class = "grid grid-cols-1 place-items-center md:grid-cols-3">
                
                
                {
                    Skills.Skills.map((items, key) => (
                        <div class = "border-transparent cursor-pointer bg-gray-900 h-12  m-6 p-1 w-48  border-grey-500 rounded-lg shadow-md flex flex-col items-center ease-in duration-300 hover:border-blue-800">
                            <h4 class = "text-white py-2 text-bold">{items.title}</h4>
                            <p class = "text-grey-500 ease-in duration-300 opacity-0  py-2 text-sm font-light w-10/12 text-center">
                                {items.description}

                            </p>
                            

                        </div>
                    ))
                }
            </div>
        </>
    )
}
export default SkillCard;