import * as FA from "react-icons/fa";
import * as BS from "react-icons/bs";
import * as AI from "react-icons/ai";
import * as GR from "react-icons/gr";
import "./Navbar.css";
import { useNavigation } from "react-router-dom";
const BlogNavbar = () => {
  return (
    <>
      <div class="absolute px-6 py-2.5 shadow-lg mx-auto sticky top-5 w-[50px] h-[50px] border-transparent rounded-full bg-gray-900 opacity-80">
        <div class="grid grid-cols-4 place-items-center">
          <div 
                class="cursor-pointer duration-300 ease-in hover:bg-white hover:text-black rounded-full h-8 w-8 grid place-items-center items-center p-0.5 text-slate-300">
            <a
                href="/"
                
                class="text-2xl">
              <div
              >
                <AI.AiOutlineHome />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogNavbar;
