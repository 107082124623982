//Components
import Navbar from "../Components/Navbar/Navbar";
import ContactForm from "../Components/ContactForm/ContactForm";
import SkillCard from "../Components/Cards/SkillCard"
import WorkResume from "../Components/Resume/WorkResume"
import EducationResume from "../Components/Resume/EducationResume"
import Blog from "../Components/Blog/Blog";
import Hero from "../Components/Hero/Hero";
import ProjectCard from "../Components/Cards/ProjectCard"
import ClassCard from "../Components/Cards/classCard"
const Home = () => {
    return(
    <div className="App h-full w-screen bg-slate-800">
      <Navbar />
      <section id="section0">
        <Hero />
      </section>
      <section id="section1">
        <WorkResume />
        <SkillCard />
      </section>
      <EducationResume />
      <ClassCard/>
      <section id="section2">
        <Blog />
      </section>
      <section id="section3">
        <ContactForm />
      </section>
    </div>
    )
}
export default Home;
