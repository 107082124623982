import { FaLinkedin, FaYoutube, FaGithub } from "react-icons/fa";
import "animate.css";
import "./Hero.css";
const Hero = () => {
  return (
    <div class="my-40 p-5 grid grid-cols-1 place-content-center place-items-center ">
      {/*<div
        class="mb-10 profile"
        style={{
          height: "204px",
          width: "204px",

          border: "1px solid white",
          borderRadius: "104px",
        }}
    ></div>}*/}
      <h2 class="text-2xl  md:text-6xl mb-5 text-white font-extrabold text-center">
        WELCOME TO MY PORTFOLIO
      </h2>
      <p class="text-sm mb-2.5 font-light w-9/12 text-gray-300">
        I'm a second year computer engineering student at Florida International
        University. I have done a multitude of projects from desigining circuits
        to writing software. Explore My resume and projects. Sometimes I even
        write articles on different projects I have completed.
      </p>
      <div class="flex flex-row place-content-center">
        <span class="m-5 text-white text-4xl">
          <a href= "https://www.linkedin.com/in/carlos-almagro-527826188"><FaLinkedin /></a>
        </span>
        <span class="m-5 text-white text-4xl">
          <a href= "https://github.com/whiteout314"><FaGithub /></a>
        </span>
        
        
      </div>
    </div>
  );
};
export default Hero;
